import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaTrash } from 'react-icons/fa';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';
import "./Adminpage.css";
import Image1 from "./images/Brain & Spine PNG.png";
import Loader from "./Loader";

const AdminPage=()=>{
  const [fromHour, setFromHour] = useState(0);
  const [toHour, setToHour] = useState(0);
  const [doctorName, setDoctorName] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [fromMinute, setFromMinute] = useState(0);
  const [toMinute, setToMinute] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [data, setData] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [doctorOptions1, setDoctorOptions1] = useState([]);

  const [appointments, setAppointments] = useState([]);

  
  const handleButtonClick = async () => {
    const dateRange = document.getElementById('date-range').value;
    const star4 = document.getElementById('4star').checked;
    const star5 = document.getElementById('5star').checked;
    const minWords = document.getElementById('min-words').value;

    const filters = {
      dateRange,
      star4,
      star5,
      minWords
    };

    try {
      const response = await fetch('https://us-central1-thephysiora-f2bf6.cloudfunctions.net/api/fetch-reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(filters)
      });
      const data = await response.json();
      console.log(data); // This will log the reviews fetched from Google API

      const successMessage = document.getElementById('success-message');
      successMessage.style.display = 'block';
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

      
    // Format the time values
    const fromTime = `${fromHour.toString().padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}`;
    const toTime = `${toHour.toString().padStart(2, '0')}:${toMinute.toString().padStart(2, '0')}`;

    // Combine from and to time
    const timeRange = `From ${fromTime} to ${toTime}`;

    // Create a data object with the extracted values
    const adminData = {
      doctorName: doctorName,
      date: startDate.toLocaleDateString(),
      timeRange: timeRange
    };

    // Push the data object to Firebase Realtime Database
    firebase.database().ref('adminData').push(adminData)
      .then(() => {
        console.log('Admin data added to Firebase');
        setSuccessMessage('Submitted successfully!');
        setDoctorName('Choose your doctor');
        setStartDate(new Date());
        setFromHour(0);
        setFromMinute(0);
        setToHour(0);
        setToMinute(0);
        window.location.href = '/adminpage';
      })
      .catch(error => {
        console.error('Error adding admin data to Firebase: ', error);
        // You can add further actions here, like showing an error message to the user
      });
  };

  
  const handleDelete = (doctorName, date, timeRange) => {
    // Remove the corresponding data from Firebase Realtime Database
    firebase.database().ref('adminData').orderByChild('doctorName').equalTo(doctorName)
      .once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          const childData = childSnapshot.val();
        if (childData.date === date && childData.timeRange === timeRange) {
            childSnapshot.ref.remove()
              .then(() => {
                console.log('Data deleted from Firebase');
                 // Update the local state to reflect the deletion
              setData(prevData => prevData.filter(item => 
                !(item.doctorName === doctorName && item.date === date && item.timeRange === timeRange)
              ));
              })
              .catch(error => {
                console.error('Error deleting data from Firebase: ', error);
              });
          }
        });
      });
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    if (!isNaN(date)) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return isoString; // Return the original string if invalid date
  };

  
  useEffect(() => {
    const filterAppointments = () => {
      let filtered = [...appointmentsData];

      if (fromDate) {
        filtered = filtered.filter((appointment) => {
          const appointmentDate = new Date(appointment.Appointmenttab_date);
          return !isNaN(appointmentDate) && appointmentDate >= new Date(fromDate);
        });
      }

      if (toDate) {
        filtered = filtered.filter((appointment) => {
          const appointmentDate = new Date(appointment.Appointmenttab_date);
          return !isNaN(appointmentDate) && appointmentDate <= new Date(toDate);
        });
      }

      filtered.sort((a, b) => new Date(a.Appointmenttab_date) - new Date(b.Appointmenttab_date));

      setFilteredAppointments(filtered);
    };

    filterAppointments();
  }, [fromDate, toDate, appointmentsData]);

  useEffect(()=>{
    document.title="Admin | Physiora"
  },[]);
  
  //handle delete appointment fields
  const handleDeleteAppointment = (date, patientName) => {
    // Remove the corresponding appointment data from Firebase Realtime Database
    firebase.database().ref('Appointment_tab')
      .orderByChild('Appointmenttab_date').equalTo(date)
      .once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          if (childSnapshot.val().Appointmenttab_name === patientName) {
            childSnapshot.ref.remove()
              .then(() => {
                console.log('Appointment data deleted from Firebase');
                setFilteredAppointments(prevAppointments => 
                  prevAppointments.filter(appointment => 
                    !(appointment.Appointmenttab_date === date && appointment.Appointmenttab_name === patientName)
                  )
                );
  
              })
              .catch(error => {
                console.error('Error deleting appointment data from Firebase: ', error);
              });
          }
        });
      });
  };

  
  
  useEffect(() => {
    // Fetch doctor options from Firebase
    const doctorRef = firebase.database().ref('Doctor Name');
    doctorRef.once('value', (snapshot) => {
      const doctors = snapshot.val();
      if (doctors) {
        const doctorNames = Object.values(doctors);
        setDoctorOptions(doctorNames);

        if (doctorNames.length > 0) {
          setDoctorName(doctorNames[0]);
        }
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = firebase.database().ref('adminData');
      dbRef.once('value', (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dataArray = Object.values(data);
          setData(dataArray);
        }else{
            setData([]);
        }
      });
    };

    fetchData();

    return () => {
        firebase.database().ref('adminData').off(); 
    };
  }, []);

  useEffect(() => {
    // Fetch doctor options from Firebase
    const doctorRef = firebase.database().ref('Doctor Name');
    doctorRef.once('value', (snapshot) => {
      const doctors = snapshot.val();
      if (doctors) {
        const doctorNames = Object.values(doctors);
        setDoctorOptions1(doctorNames);

        if (doctorNames.length > 0) {
          setDoctorName(doctorNames[0]);
        }
      }
    });
  }, []);

  
 
  useEffect(() => {
    const fetchAppointments = () => {
      const appointmentsRef = firebase.database().ref('Appointment_tab');
      appointmentsRef.once('value', (snapshot) => {
        const appointments = snapshot.val();
        if (appointments) {
          const appointmentsArray = Object.values(appointments);
          setAppointmentsData(appointmentsArray);
        } else {
          setAppointmentsData([]);
        }
      });
    };
    fetchAppointments();
    return () => {
      firebase.database().ref('Appointment_tab').off();
    };
  }, []);

    return(
        <div className="App">
        <Header/><br></br><br></br><br></br><br></br><br></br>
        <Loader/>
        {/* <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
        <h2 className="mb-2" style={{color: "#853f91"}}>Trigger Review</h2><br></br>
        <select id="date-range" className="mb-3">
          <option value="1">Last one month</option>
          <option value="3">Last three months</option>
          <option value="6">Last six months</option>
          <option value="12">One year</option>
          <option value="24">Two years</option>
        </select><br></br>
        <label><input type="checkbox" id="4star" value="4"/> 4 Star</label>
        <label><input type="checkbox" id="5star" value="5"/> 5 Star</label><br></br>
        <select id="min-words" className="mb-3">
          <option value="20">Minimum 20 words</option>
          <option value="30">Minimum 30 words</option>
          <option value="40">Minimum 40 words</option>
          <option value="50">Minimum 50 words</option>
        </select><br></br>
      
        <button className='styled-button' onClick={handleButtonClick} style={{color:"white",backgroundColor:"#853f91",borderColor:"#853f91"}}>Fetch and Store Reviews</button>
        <div id="success-message" className="success-message" style={{display: "none", color: "green"}}>Reviews fetched and stored successfully!</div>
        </div>
      </div> */}
      <div className="row">
        <div className="col">
          <form onSubmit={handleSubmit} className="admin_form">
            <h4>
              Book <span>Appointment</span>
            </h4>
            <div className="form-row ">
            {doctorOptions.length > 0 && (
              <div className="form-group col-lg-4">
                <label htmlFor="inputDoctorName">Doctor Name</label>
                <select
                  name=""
                  className="form-control wide"
                  id="inputDoctorName"
                  value={doctorName}
                  onChange={(e) => setDoctorName(e.target.value)}
                >
                  {doctorOptions.map((doctor, index) => (
                    <option key={index}>{doctor}</option>
                  ))}
                </select>
              </div>
            )}
            </div>
            <div className='form-row'>
            <div className="form-group col-lg-4">
                <label htmlFor="inputDate">Choose Date </label>
                <div className="input-group date">
                <DatePicker
                  id="inputDate"
                  className="form-control"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                />
                </div>
              </div>
              </div>
              <div className="form-row time-select-container">
                <div className="form-group col-lg-2">
                    <label htmlFor="fromTime">From</label>
                    <div className="input-group date">
                        <select className="form-control" id="fromHour"  value={fromHour}
                      onChange={(e) => setFromHour(e.target.value)}>
                            {[...Array(24)].map((_, hour) => (
                            <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                            ))}
                        </select>
                        <span className="input-group-text">:</span>
                        <select className="form-control" id="fromMinute" value={fromMinute}
                      onChange={(e) => setFromMinute(e.target.value)}>
                            {[...Array(60)].map((_, minute) => (
                            <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                        ))}
                        </select>
                    </div>
                </div>
             <div className="form-group col-lg-2">
                <label htmlFor="toTime">To</label>
                <div className="input-group date">
                    <select className="form-control" id="toHour" value={toHour}
                      onChange={(e) => setToHour(e.target.value)}>
                        {[...Array(24)].map((_, hour) => (
                        <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                        ))}
                    </select>
                    <span className="input-group-text">:</span>
                    <select className="form-control" id="toMinute"   value={toMinute}
                      onChange={(e) => setToMinute(e.target.value)}>
                        {[...Array(60)].map((_, minute) => (
                        <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                        ))}
                    </select>
                </div>
            </div>
            
            </div>
            <div className="btn-box adminpagebutton">
              <button type="submit" className="btn adminpagebutton" style={{color:"white",backgroundColor:"#853f91",borderColor:"#853f91",width:"200px"}}>
                Submit
              </button>
            </div>
            {successMessage && <p>{successMessage}</p>}
         
          </form>
        </div>
      </div><br></br>
      

  
  <div className="admin_table_section layout_padding-bottom">
    <div className="container">
    <div className="table-container"></div>
    <h2>Appointment Table</h2>
    <div className="mb-3">
    <PDFDownloadLink
      document={<PDFDocument data={data} />}
        fileName="appointment_table.pdf"
        >
        {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Export in pdf'
        }
    </PDFDownloadLink>
    </div>
    <table id="adminTable">
   
        <thead>
          <tr>
            <th style={{color:"black"}}>Doctor Name</th>
            <th style={{color:"black"}}>Date</th>
            <th style={{color:"black"}}>Time</th>
            <th style={{color:"black"}}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.doctorName}</td>
              <td>{format(new Date(item.date), 'dd/MM/yyyy')}</td>
              <td>{item.timeRange}</td>
              <td><FaTrash onClick={(e) =>{e.preventDefault(); handleDelete(item.doctorName, item.date, item.timeRange);}}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table><br></br>
    </div>
    </div>

    <div className="appointments_table_section layout_padding-bottom">
      <div className="container">
        <div className="table-container"></div>
        <h2>Patient Appointments</h2>
        <div className="mb-3">
          <PDFDownloadLink
            document={<PDFDocument1 appointmentsData={filteredAppointments} />}
            fileName="patient_appointments.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Export in pdf'
            }
          </PDFDownloadLink>
        </div>
        <div className="date-filters">
          <div className="filter">
            <label>From:</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="filter">
            <label>To:</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="filter">
            <label>Select Doctor:</label>
            <select
              value={selectedDoctor}
              onChange={(e) => setSelectedDoctor(e.target.value)}
            >
              
              {doctorOptions1.map((doctor, index) => (
                <option key={index}>{doctor}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-responsive">
          <table id="appointmentsTable">
            <thead>
              <tr>
                <th style={{ color: 'black' }}>Date</th>
                <th style={{ color: 'black' }}>Time</th>
                <th style={{ color: 'black' }}>Name</th>
                <th style={{ color: 'black' }}>Email</th>
                <th style={{ color: 'black' }}>Doctor Name</th>
               
                <th style={{ color: 'black' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredAppointments.map((item, index) => (
                <tr key={index}>
                  <td>{formatDate(item.Appointmenttab_date)}</td>
                  <td>{item.Appointmenttab_time}</td>
                  <td>{item.Appointmenttab_name}</td>
                  <td>{item.Appointmenttab_email}</td>
                  <td>{item.Appointmenttab_doctorname}</td>
                
                  <td>
                    <FaTrash
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteAppointment(item.Appointmenttab_date, item.Appointmenttab_name);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
        </div>
      <Footer/>
      </div>
    );
}


const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
    position: 'relative',
  },
  header: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2c5fa',
    paddingVertical: 10,
  },
  headerTextContainer: {
    marginLeft: 10,
  },
  headerTitle: {
    fontSize: 25,
    color: '#853f91',
    textAlign: 'center',
  },
  headerSubtitle: {
    fontSize: 10,
    textAlign: 'center',
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    backgroundColor: '#00ACED',
    padding: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#853f91',
    backgroundColor: '#853f91',
    padding: 5,
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    padding: 5,
    backgroundColor: 'white',
    fontSize: '10px',
  },
  section: {
    marginBottom: 10,
  
  },
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={Image1} style={{width: 50,height: 50}}/>
        <Text style={{textAlign:"center",fontSize:"25px",color:"#853f91",marginLeft:"100px"}}>THE PHYSIORA</Text><br></br>
        <Text style={{textAlign:"center",fontSize:"10px",marginLeft:"80px"}}>Doctor Appointment</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Time</Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View
              key={index}
              style={index % 2 === 0 ? { ...styles.tableRow, backgroundColor: '#DDDDDD' } : styles.tableRow}
            >
              <View style={styles.tableCol}>
                <Text>{item.doctorName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{format(new Date(item.date), 'dd/MM/yyyy')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.timeRange}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer}>
        <Text>OMR,Egattur,Chennai</Text>
        <Text>thephysiora@gmail.com</Text>
        <Text>+91 89254 49512</Text>
      </View>
    </Page>
  </Document>
);

const PDFDocument1 = ({ appointmentsData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
    <View style={styles.header}>
        <Image src={Image1} style={{width: 50,height: 50}}/>
        <Text style={{textAlign:"center",fontSize:"25px",color:"#853f91",marginLeft:"100px"}}>THE PHYSIORA</Text><br></br>
        <Text style={{textAlign:"center",fontSize:"10px",marginLeft:"80px"}}>Patient Appointment</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Time</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Patient Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Email</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
          </View>
          {appointmentsData.map((item, index) => (
            <View
              key={index}
              style={
                index % 2 === 0
                  ? { ...styles.tableRow, backgroundColor: '#DDDDDD' }
                  : styles.tableRow
              }
            >
              <View style={styles.tableCol}>
                <Text>{formatDates(item.Appointmenttab_date)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_time}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_doctorname}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer}>
        <Text>OMR,Egattur,Chennai</Text>
        <Text>thephysiora@gmail.com</Text>
        <Text>+91 89254 49512</Text>
      </View>
    </Page>
  </Document>
);

const formatDates = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'dd/MM/yyyy');
};


export default AdminPage;