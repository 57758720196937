import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Image1 from "./images/Brain & Spine PNG.png";
import { FaTrash, FaEdit } from 'react-icons/fa';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Link } from 'react-router-dom';
import Loader from "./Loader";
import AttendanceCalculate from "./components/AttendanceCalculate";

const TableDoctorSchedule=()=>{
    
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [doctorOptions1, setDoctorOptions1] = useState([]);
  const [doctorName, setDoctorName] = useState('');
  
  useEffect(()=>{
    document.title="Time Sheet | Physiora"
  },[]);

  
  useEffect(() => {
    // Fetch doctor options from Firebase
    const doctorRef = firebase.database().ref('/Doctor Details');
    doctorRef.once('value', (snapshot) => {
      const doctors = snapshot.val();
      if (doctors) {
        const doctorNames = Object.keys(doctors).map(key => ({
          value: doctors[key].doctorId,
          name: doctors[key].Name,
          ...doctors[key]
        }));
        setDoctorOptions1(doctorNames);

        if (doctorNames.length > 0) {
          setDoctorName(doctorNames[0]);
        }
      }
    });
  }, []);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    if (!isNaN(date)) {
      return format(date, 'dd/MM/yyyy');
    }
    return 'Invalid date';
  };

  const handleDeleteAppointment = (fromHour, fromMinute, toHour, toMinute, date, doctorName) => {
    const appointmentsRef = firebase.database().ref('Doctor Attendance');
    appointmentsRef.once('value', (snapshot) => {
      const appointments = snapshot.val();
      if (appointments) {
        const appointmentKey = Object.keys(appointments).find(key => {
          const appointment = appointments[key];
          return (
            appointment.fromHour === fromHour &&
            appointment.fromMinute === fromMinute &&
            appointment.toHour === toHour &&
            appointment.toMinute === toMinute &&
            appointment.selectedDate === date &&
            appointment.doctorName === doctorName
          );
        });

        if (appointmentKey) {
          appointmentsRef.child(appointmentKey).remove()
            .then(() => {
              console.log('Appointment data deleted from Firebase');
              setFilteredAppointments(prevAppointments =>
                prevAppointments.filter(appointment =>
                  !(appointment.fromHour === fromHour &&
                    appointment.fromMinute === fromMinute &&
                    appointment.toHour === toHour &&
                    appointment.toMinute === toMinute &&
                    appointment.selectedDate === date &&
                    appointment.doctorName === doctorName)
                )
              );
            })
            .catch(error => {
              console.error('Error deleting appointment data from Firebase: ', error);
            });
        }
      }
    });
  };

  const [appointmentsData, setAppointmentsData] = useState([]);
  useEffect(() => {
    const fetchAppointments = () => {
      const appointmentsRef = firebase.database().ref('Doctor Attendance');
      appointmentsRef.once('value', (snapshot) => {
        const appointments = snapshot.val();
        if (appointments) {
          const appointmentsArray = Object.keys(appointments).map(key => ({
            ...appointments[key],
            id: key,
          }));
          setAppointmentsData(appointmentsArray);
        } else {
          setAppointmentsData([]);
        }
      });
    };
    fetchAppointments();
    return () => {
      firebase.database().ref('Doctor Details').off();
    };
  }, []);
  useEffect(() => {
    const filterAppointments = () => {
      let filtered = [...appointmentsData];

      if (fromDate) {
        filtered = filtered.filter((appointment) => {
          const appointmentDate = new Date(appointment.selectedDate);
          return !isNaN(appointmentDate) && appointmentDate >= new Date(fromDate);
        });
      }

      if (toDate) {
        filtered = filtered.filter((appointment) => {
          const appointmentDate = new Date(appointment.selectedDate);
          return !isNaN(appointmentDate) && appointmentDate <= new Date(toDate);
        });
      }

      if (selectedDoctor) {
        filtered = filtered.filter(appointment => appointment.doctorName === selectedDoctor);
      }

      filtered.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate));
      setFilteredAppointments(filtered);
    };

    filterAppointments();
  }, [fromDate, toDate, selectedDoctor, appointmentsData]);

    return(
        <div className="App">
        <Header/>
        <Loader/>
      <div className="appointments_table_section layout_padding-bottom" style={{marginTop: "150px"}}>
      <div className="container">
        <div className="table-container"></div>
        <h2>Attendance Schedule</h2>
        <div className="mb-3">
          <PDFDownloadLink
            document={<PDFDocument1 appointmentsData={filteredAppointments} />}
            fileName="patient_appointments.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Export in pdf'
            }
          </PDFDownloadLink>
        </div>
        <div className="date-filters">
          <div className="filter">
            <label>From:</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="filter">
            <label>To:</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="filter">
            <label>Select Doctor:</label>
            <select
              value={selectedDoctor}
              onChange={(e) => setSelectedDoctor(e.target.value)}
            >
              <option value="">All</option>
              {doctorOptions1.map((doctor, index) => (
                <option key={index} value={doctor.name}>{doctor.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-responsive">
          <table id="appointmentsTable">
            <thead>
              <tr>
                <th style={{ color: 'black' }}>Date</th>
                <th style={{ color: 'black' }}>From</th>
                <th style={{ color: 'black' }}>To</th>
                <th style={{ color: 'black' }}>Doctor Name</th>
                <th style={{ color: 'black' }}>Edit</th>
                <th style={{ color: 'black' }}>Delete</th>
              </tr>  
            </thead>
            <tbody>
              {filteredAppointments.map((item, index) => (
                <tr key={item.id}>
                  <td>{formatDate(item.selectedDate)}</td>
                  <td>{`${item.fromHour || '00'}:${(item.fromMinute || 0).toString().padStart(2, '0')}`}</td>
                  <td>{`${item.toHour || '00'}:${(item.toMinute || 0).toString().padStart(2, '0')}`}</td>
                  <td>{item.doctorName}</td>
                  <td><Link to={`/attendance-update/${item.attendanceId}`}>
                    <FaEdit style={{ marginRight: "10px", cursor: "pointer" }} />
                  </Link>
                </td>
                  <td>
                    <FaTrash
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteAppointment(item.fromHour,
                            item.fromMinute,
                            item.toHour,
                            item.toMinute,
                            item.selectedDate,
                            item.doctorName);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <AttendanceCalculate/>
        <Footer/>
        </div>
    )
}



const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 12,
      position: 'relative',
    },
    header: {
      fontSize: 16,
      marginBottom: 20,
      textAlign: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f2c5fa',
      paddingVertical: 10,
    },
    headerTextContainer: {
      marginLeft: 10,
    },
    headerTitle: {
      fontSize: 25,
      color: '#853f91',
      textAlign: 'center',
    },
    headerSubtitle: {
      fontSize: 10,
      textAlign: 'center',
    },
    footer: {
      fontSize: 10,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      backgroundColor: '#00ACED',
      padding: 10,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      marginTop: 20,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '33.33%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#853f91',
      backgroundColor: '#853f91',
      padding: 5,
    },
    tableCol: {
      width: '33.33%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'white',
      padding: 5,
      backgroundColor: 'white',
      fontSize: '10px',
    },
    section: {
      marginBottom: 10,
    
    },
  });
  
  const PDFDocument1 = ({ appointmentsData }) => (
    <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.header}>
          <Image src={Image1} style={{width: 50,height: 50}}/>
          <Text style={{textAlign:"center",fontSize:"25px",color:"#853f91",marginLeft:"100px"}}>THE PHYSIORA</Text><br></br>
          <Text style={{textAlign:"center",fontSize:"10px",marginLeft:"80px"}}>Patient Appointment</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>Date</Text>
            <Text style={styles.tableColHeader}>From</Text>
            <Text style={styles.tableColHeader}>To</Text>
            <Text style={styles.tableColHeader}>Doctor Name</Text>
            </View>
            {appointmentsData.map((appointment, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{formatDates(appointment.selectedDate)}</Text>
              <Text style={styles.tableCol}>{`${appointment.fromHour || '00'}:${(appointment.fromMinute || 0).toString().padStart(2, '0')}`}</Text>
              <Text style={styles.tableCol}>{`${appointment.toHour || '00'}:${(appointment.toMinute || 0).toString().padStart(2, '0')}`}</Text>
              <Text style={styles.tableCol}>{appointment.doctorName}</Text>
            </View>
          ))}
          </View>
        </View>
        <View style={styles.footer}>
          <Text>OMR,Egattur,Chennai</Text>
          <Text>thephysiora@gmail.com</Text>
          <Text>+91 89254 49512</Text>
        </View>
      </Page>
    </Document>
  );
  
  const formatDates = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid date';
    return format(date, 'dd/MM/yyyy');
  };

export default TableDoctorSchedule;