import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment, useRef, useState, useEffect } from "react";
import { isObject, isArray, cloneDeep } from "lodash";

import { API_ENDPOINT, PayslipSampleData } from "./common";
import { CompanyInfo, EmployeeInfo, EarningAndDeduction, Success, Alert } from "./components";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { FaTrash } from 'react-icons/fa';
import axios from "axios";
import Loader from "./Loader";
import "./Payslip.css";
const  REST_FETCH_API = `https://us-central1-thephysiora-f2bf6.cloudfunctions.net/api/payslip`;

const initialData = {
  company: {
    icon: null,
    iconUrl: "",
    name: "",
    address: "",
  },
  employee: {
    name: "",
    email: "",
    id: "",
    position: "",
    joiningDate: null,
    uan: "",
    accountNumber: "",
    pfAccountNumber: "",
    paidDays: 0,
    lopDays: 0,
  },
  earnings: [],
  deductions: [],
  reimbursements: [],
};

export default function PayslipForm() {
  const [result, setResult] = useState(null);
  const [alert, setAlert] = useState({ open: false, type: "", children: "" });
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); 

  const templateData = useRef(cloneDeep(initialData));
  const companyRef = useRef();
  const employeeRef = useRef();
  const earningRef = useRef();
  const deductionRef = useRef();
  const reimbursementRef = useRef();

  useEffect(()=>{
    document.title="Payslip | Physiora"
  },[]);


  const handleReset = () => {
    templateData.current = cloneDeep(initialData);

    companyRef.current.reset(templateData.current.company);
    employeeRef.current.reset(templateData.current.employee);
    earningRef.current.reset(templateData.current.earnings);
    deductionRef.current.reset(templateData.current.deductions);
    reimbursementRef.current.reset(templateData.current.reimbursements);
  };

  const onSetSampleData = () => {
    templateData.current = cloneDeep(PayslipSampleData);
    console.log("employeeref:",employeeRef);
    // companyRef.current.set(templateData.current.company);
    employeeRef.current.set(templateData.current.employee);
    earningRef.current.set(templateData.current.earnings);
    deductionRef.current.set(templateData.current.deductions);
    reimbursementRef.current.set(templateData.current.reimbursements);
  };

  const handleFetchRequest = (type) => {
    return async (event) => {
      event.preventDefault();

      console.log("Fetch request type:", type);
    console.log("Current template data before fetch:", templateData.current);
      // if (!templateData.current.company.name || !templateData.current.employee.name || !templateData.current.employee.email) {
      //   setAlert({ open: true, type: "error", children: "All required fields must be filled out." });
      //   return;
      // }

      templateData.current.type = type;

      if (type === "download") {
        setIsDownloadLoading(true);
      } else {
        setIsEmailLoading(true);
      }

      var form = new FormData();

      for (const key in templateData.current) {
        if (isObject(templateData.current[key]) && !isArray(templateData.current[key])) {
          for (const subKey in templateData.current[key]) {
            if (templateData.current[key][subKey]) {
              form.append(key + subKey.charAt(0).toUpperCase() + subKey.slice(1), templateData.current[key][subKey]);
              console.log(`Appending field: ${key + subKey.charAt(0).toUpperCase() + subKey.slice(1)} with value: ${templateData.current[key][subKey]}`);
            }
          }
        } else if (isArray(templateData.current[key])) {
          form.append(key, JSON.stringify(templateData.current[key]));
          console.log(`Appending array field: ${key} with value: ${JSON.stringify(templateData.current[key])}`);
        } else {
          if (templateData.current[key]) {
            form.append(key, templateData.current[key]);
            console.log(`Appending field: ${key} with value: ${templateData.current[key]}`);
          }
        }
      }

      console.log("form:",form);
      form.forEach((value, key) => {
        console.log(key, value);
      });
      try {
        const response = await axios.post("https://us-central1-thephysiora-f2bf6.cloudfunctions.net/api/payslip", form, {
          headers: {
           "Content-Type": "application/json",
          },
          responseType: type === "download" ? "blob" : "json",
        });
  
        if (type === "download") {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "payslip.pdf");
          document.body.appendChild(link);
          link.click();
          setIsDownloadLoading(false);
          setAlert({ open: true, type: "success", children: "Successfully Downloaded." });
        } else {
          setResult(response.data);
          setIsEmailLoading(false);
        }
      } catch (err) {
        setAlert({ open: true, type: "error", children: err.message });
        setIsDownloadLoading(false);
        setIsEmailLoading(false);
      }
    };
  };

  const [data, setData] = useState([]);

  const handleDelete = async (id) => {
    await firebase.database().ref(`payslips/${id}`).remove();
    setData(prevData => prevData.filter(item => item.id !== id));
  };


  
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = firebase.database().ref('payslip');
      dbRef.once('value', (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dataArray = Object.keys(data).map(key => ({
            id: key, // Store the key as id
            ...data[key]
          }));
          setData(dataArray);
        } else {
          setData([]);
        }
      });
    };

    fetchData();

    return () => {
      firebase.database().ref('payslip').off(); 
    };
  }, []);

  return (
    <div className="App">
    <Header/>
    <Loader/>
    <Fragment>
      <Alert {...alert} duration={5000} onClose={() => setAlert({ ...alert, open: false })} />
      <main className="layout" style={{marginTop:"120px",backgroundColor:"#f2c5fa"}}>
        <div className="paper">
          <h1 style={{fontsize:"20px",color:"#853f91",textAlign:"center",textTransform:"uppercase",paddingTop:"50px"}}>Payslip Generator</h1>
          {result ? (
            <Success result={result} setResult={setResult} />
          ) : (
            <Fragment>
              <div className="payleft">
                {/* <button style={{width:"250px"}} className="buttonpayslip" onClick={onSetSampleData}>
                  Apply with Sample Data
                </button> */}
                {/* <CompanyInfo templateData={templateData} ref={companyRef} /> */}
                <EmployeeInfo templateData={templateData} ref={employeeRef} />
                {/* <EarningAndDeduction type="earning" templateData={templateData} ref={earningRef}/><br></br> */}
                {/* <EarningAndDeduction type="deduction" templateData={templateData} ref={deductionRef} /><br></br> */}
                <EarningAndDeduction type="reimbursement" templateData={templateData} ref={reimbursementRef} />
              </div>

              <div className="checkbox-container" style={{ textAlign: "center", marginTop: "20px" }}>
                  <input
                    type="checkbox"
                    id="enableButtons"
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  />
                  <label htmlFor="enableButtons" style={{ marginLeft: "10px" }}>Please Check One Time Entered Details</label>
                </div>

              <div className="button-container" style={{ width: "40%", display: "flex", justifyContent: "center", marginTop: "20px"}}>
              <button onClick={handleReset} className="button">
                Reset
              </button>
              <button
                    onClick={handleFetchRequest("download")}
                    disabled={!isCheckboxChecked || isDownloadLoading}
                    className="button"
                    style={{ marginLeft: "10px" }}
                  >
                {isDownloadLoading ? "Loading..." : "Download as PDF"}
              </button>
              <button
                    onClick={handleFetchRequest("email")}
                    disabled={!isCheckboxChecked || isEmailLoading}
                    className="button"
                    style={{ marginLeft: "10px" }}
                  >
                {isEmailLoading ? "Loading..." : "Send as Email"}
              </button>
            </div>

            </Fragment>
          )}
        </div>
      </main>
    </Fragment>
    
    <div className="admin_table_section layout_padding-bottom" style={{marginTop: "100px"}}>
      <div className="container">
        <div className="table-container">
          <h2>Latest Payslip</h2>
          <table id="adminTable">
            <thead>
              <tr>
                <th style={{color:"black"}}>Employee Email</th>
                <th style={{color:"black"}}>Employee Name</th>
                <th style={{color:"black"}}>Generated Date</th>
                <th style={{color:"black"}}>Generated Month</th>
                <th style={{color:"black"}}>View PDF</th>
                <th style={{color:"black"}}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.employeeEmail}</td>
                  <td>{item.employeeName}</td>
                  <td>{item.generatedDate}</td>
                  <td>{item.generatedMonth}</td>
                  <td>
                    <a href={item.pdfUrl} target="_blank" rel="noopener noreferrer">
                      View PDF
                    </a>
                  </td>
                  <td>
                    <FaTrash onClick={(e) => { e.preventDefault(); handleDelete(item.id); }} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}
