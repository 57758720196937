import React,{useEffect, useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import Loader from './Loader';
import "./Profile.css";

const Profile=()=>{
    useEffect(()=>{
        document.title="Profile | Physiora"
      },[]);
  

const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [age, setAge] = useState('');
const [phoneNumber, setPhoneNumber] = useState('');
const [gender, setGender] = useState('');
const [location, setLocation] = useState('');
const [message, setMessage] = useState('');

useEffect(() => {
  const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
          const userId = currentUser.uid;
          const userRef = firebase.database().ref('users/' + userId);

          // Fetch user data from the Realtime Database
          userRef.once('value', (snapshot) => {
              const userData = snapshot.val();
              if (userData) {
                  setFirstName(userData.firstName || '');
                  setLastName(userData.lastName || '');
                  setEmail(userData.email || '');
                  setAge(userData.age || '');
                  setPhoneNumber(userData.phoneNumber || '');
                  setGender(userData.gender || '');
                  setLocation(userData.location || '');
              } else {
                  setMessage('User data not found');
              }
          }, (error) => {
              console.error('Error fetching user data:', error);
              setMessage('Error fetching user data');
          });
      } else {
          setMessage('User not authenticated');
      }
  });

  // Clean up the subscription on component unmount
  return () => unsubscribe();
}, []);


useEffect(() => {
    // Store form data in localStorage
    localStorage.setItem('profileFormData', JSON.stringify({
      firstName,
      lastName,
      email,
      age,
      phoneNumber,
      gender,
      location
    }));
  }, [firstName, lastName, email, age, phoneNumber, gender, location]);
  
  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const usersRef = firebase.database().ref('users');
          usersRef.orderByChild('email').equalTo(email).once('value', (snapshot) => {
              if (snapshot.exists()) {
                  const userId = Object.keys(snapshot.val())[0];
                  const userRef = firebase.database().ref('users/' + userId);
  
                  userRef.update({
                      firstName,
                      lastName,
                      email,
                      age,
                      phoneNumber,
                      gender,
                      location
                  }).then(() => {
                      setMessage('Profile updated successfully');
                  }).catch((error) => {
                      console.error('Error updating profile:', error);
                      setMessage('Error updating profile');
                  });
              } else {
                  setMessage('No user found with this email');
              }
          }, (error) => {
              console.error('Error querying user data:', error);
              setMessage('Error querying user data');
          });
     };
  
    return(
        <div className='App'>
            <Header/>
            <Loader/>
    <div className="container" style={{marginTop:"120px"}}>

      <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
          <h2 className="mb-2" style={{color:"#853f91"}}>My Profile</h2>
        </div>
      </div>
      <div className="row contactform">
            <div className="col-md-7">
              <div className="form_container">
              <form onSubmit={handleUpdateProfile}>
                    <div className="form-group1">
                        <label htmlFor="firstName">First Name:</label>
                        <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className="form-group1">
                        <label htmlFor="lastName">Last Name:</label>
                        <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className="form-group1">
                        <label htmlFor="email">Email:</label>
                        <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group1">
                        <label htmlFor="age">Age:</label>
                        <input
                            type="number"
                            className="form-control"
                            id="age"
                            value={age}
                            min={0}
                            max={120}
                            onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 && value <= 120) {
                                setAge(value);
                            } else if (value > 120) {
                                setAge(120);
                            }
                            }}
                        />
                        </div>

                    <div className="form-group1">
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input type="tel" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <div className="form-group1">
                      <label>Gender:</label>
                        <div className="radio-container">
                            <input type="radio" id="male" name="gender" value="male" checked={gender === 'male'} onChange={(e) => setGender(e.target.value)} />
                            <label for="male" className="radio-label">Male</label>
                        </div>
                        <div className="radio-container">
                            <input type="radio" id="female" name="gender" value="female" checked={gender === 'female'} onChange={(e) => setGender(e.target.value)} />
                            <label for="female" className="radio-label">Female</label>
                        </div>
                    </div>
                    <div className="form-group1">
                        <label htmlFor="location">Location:</label>
                        <input type="text" className="form-control" id="location" value={location} onChange={(e) => setLocation(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary">Update Profile</button>
                </form>
                <p>{message}</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="img-box">
                {/* You can place an image here if needed */}
              </div>
            </div>
          </div>
      </div><br></br>

            <Footer/>
        </div>
    );
}
export default Profile;