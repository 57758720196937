import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import firebase from "firebase/compat/app";
import "./Specialization.css";
import Loader from "./Loader";

const FitForFifty=()=>{
    const categories = ["Personalized Exercise Plans", "Fall Prevention Strategies", "Pain Management", "Nutritional Guidance", "Lifestyle Modifications"];
    const [activeIndex, setActiveIndex] = useState(0);


    const [services, setServices] = useState({});

    useEffect(()=>{
      document.title="Fit Over Fifty | Physiora"
    },[]);

    useEffect(() => {
        const fetchServices = async () => {
          const db = firebase.database().ref("Services2");
          db.once('value').then(snapshot => {
            setServices(snapshot.val());
          });
        };
        fetchServices();
      }, []);


    useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex((prevIndex) => (prevIndex + 1) % categories.length);
        }, 18000);
    
        return () => clearInterval(interval);
      }, []);

      const activeCategory = categories[activeIndex];

    return(
        <div className="App">
        <Header/>
        <Loader/>
        <div className="ftco-section" style={{marginTop:"200px"}}>
<div className="container">
  <div className="row justify-content-center mb-5 pb-5">
    <div className="col-md-7 text-center heading-section ftco-animate">
      <h2 className="mb-2" style={{color:"#853f91"}}>Our Fit Over Fifty Service</h2>
      <p >
      Our team of experienced therapists utilizes cutting-edge techniques and personalized treatment plans to address your unique needs.
      </p>
    </div>
  </div>
  <div className="container">
  <div id="tabs" className="d-flex justify-content-center mb-4 tabs-container">
    {categories.map((category, index) => (
      <div
        key={index}
        className={`tab tabts  ${index === activeIndex ? 'active' : ''}`}
        onClick={() => setActiveIndex(index)}
        style={{
          cursor: 'pointer',
          padding: '10px 20px',
          margin: '0 5px',
          backgroundColor: index === activeIndex ? '#A890FE' : 'white',
          color: index === activeIndex ? '#fff' : '#000',
        }}
      >
        {category}
      </div>
    ))}
  </div><br></br><br></br>

  <div id="content">
    {services[activeCategory] && (
      <div className="row">
        {services[activeCategory].slice(1).map((item) => (
          <div key={item.id} className="col-md-3 d-flex align-self-stretch ftco-animate">
            <div className="media block-6 services d-block text-center">
              <div className="icon d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{ __html: item.Icon }} style={{marginTop:"20px"}}/>
                
             
              <div className="media-body p-2 mt-3">
                <h3 className="heading">{item.Name}</h3>
                <p className='text-justify' style={{textAlign:"justify"}}>{item.Value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
  <style jsx>{`
    .tab {
      display: inline-block;
      margin: 10px;
      padding: 10px 20px;
      background-color: white;
      cursor: pointer;
      border-radius: 10px;
      border: 2px solid #A890FE;
      border-color: #A890FE;
    }
    .tab.active {
      background-color: #007bff;
      color: white;
    }
    .content {
      display: none;
      margin-top: 20px;
    }
    .content.active {
      display: block;
    }
  `}</style>
</div>
</div>
</div>
        <Footer/>
    </div>
    );
}

export default FitForFifty;