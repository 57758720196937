import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import "./PaymentDetails.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';

const PaymentDetails = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        try {
          const response = await axios.get('https://us-central1-thephysiora-f2bf6.cloudfunctions.net/api/getUserPayments', {
            params: { userId }
          });

          // Sort the payments by timestamp in descending order
          const sortedPayments = Object.values(response.data).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setPaymentData(sortedPayments);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching payment data:", error);
          setLoading(false);
        }
      } else {
        console.log("No user is logged in");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = paymentData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="App">
      <Header />
      <Loader />
      <div className="allpayment_section layout_padding mb-4" style={{ marginTop: "200px" }}>
        <div className="container">
          <h1 className="text-center mb-4" style={{ color: "#853f91", textTransform: "uppercase" }}>
            Latest Payments
          </h1>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <div id="paymentsdetails" style={{ gap: "10px" }}>
                {currentItems.length === 0 ? (
                  <p>No payment data available.</p>
                ) : (
                  currentItems.map((payment, index) => (
                    <div key={index} className="paymentdetails mb-4">
                      <div className="details-left">
                        <i type="service" className="iconpdetails far fa-credit-card"></i>
                        <p className="timesnap">{payment.timestamp}</p>
                        <h5 className="mb-3">Payment ID: {payment.paymentId}</h5>
                        <h5>Appointment Status: {payment.appointmentStatus}</h5>
                        <h5>Email Id: {payment.email}</h5>
                      </div>
                      <div className="details-center">
                        <p>Patient Name: <span className="bold">{payment.patientName}</span></p>
                        <p>Appointment Date: <span className="bold">{formatDate(payment.AppointmentDate)}</span></p>
                        <p>Appointment Time: <span className="bold">{payment.AppointmentTime}</span></p>
                      </div>
                      <div className="details-right">
                        {/* Any additional content for the right section */}
                      </div>
                    </div>
                  ))
                )}
              </div>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={paymentData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item">
          <button
            onClick={() => paginate(currentPage - 1)}
            className="page-link"
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            onClick={() => paginate(currentPage + 1)}
            className="page-link"
            disabled={currentPage === pageNumbers.length}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default PaymentDetails;
