import React, { useState, useEffect } from "react";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Image1 from "../images/Brain & Spine PNG.png"; // Ensure this path is correct
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { format } from 'date-fns';

const AttendanceCalculate = () => {
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [doctorOptions1, setDoctorOptions1] = useState([]);
  
    useEffect(() => {
        document.title = "Time Sheet | Physiora";
    }, []);
  
    useEffect(() => {
        const doctorRef = firebase.database().ref('/Doctor Details');
        doctorRef.once('value', (snapshot) => {
            const doctors = snapshot.val();
            if (doctors) {
                const doctorNames = Object.keys(doctors).map(key => ({
                    value: doctors[key].doctorId,
                    name: doctors[key].Name,
                    ...doctors[key]
                }));
                setDoctorOptions1(doctorNames);
            }
        });
    }, []);
  
    const calculateAttendanceStats = (appointments) => {
        const stats = {};
        appointments.forEach((appointment) => {
            const { doctorName, status } = appointment;
            if (!stats[doctorName]) {
                stats[doctorName] = { totalDays: 0, present: 0, leave: 0 };
            }
            stats[doctorName].totalDays += 1;
            if (status === 'Present') {
                stats[doctorName].present += 1;
            } else if (status === 'Leave') {
                stats[doctorName].leave += 1;
            }
        });
        return stats;
    };
  
    const [appointmentsData, setAppointmentsData] = useState([]);
    useEffect(() => {
        const fetchAppointments = () => {
            const appointmentsRef = firebase.database().ref('Doctor Attendance');
            appointmentsRef.once('value', (snapshot) => {
                const appointments = snapshot.val();
                if (appointments) {
                    const appointmentsArray = Object.keys(appointments).map(key => ({
                        ...appointments[key],
                        id: key,
                    }));
                    setAppointmentsData(appointmentsArray);
                } else {
                    setAppointmentsData([]);
                }
            });
        };
        fetchAppointments();
    }, []);
  
    useEffect(() => {
        const filterAppointments = () => {
            let filtered = [...appointmentsData];
            if (fromDate) {
                filtered = filtered.filter(appointment => new Date(appointment.selectedDate) >= new Date(fromDate));
            }
            if (toDate) {
                filtered = filtered.filter(appointment => new Date(appointment.selectedDate) <= new Date(toDate));
            }
            if (selectedDoctor) {
                filtered = filtered.filter(appointment => appointment.doctorName === selectedDoctor);
            }
            filtered.sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate));
            setFilteredAppointments(filtered);
        };
        filterAppointments();
    }, [fromDate, toDate, selectedDoctor, appointmentsData]);

    const attendanceStats = calculateAttendanceStats(filteredAppointments);
  
    return (
        <div className="appointments_table_section layout_padding-bottom" style={{ marginTop: "150px" }}>
            <div className="container">
                <h2>Attendance Calculate</h2>
                <PDFDownloadLink document={<PDFDocument1 attendanceStats={attendanceStats} />} fileName="attendance_report.pdf">
                    {({ loading }) => loading ? 'Loading document...' : 'Export in pdf'}
                </PDFDownloadLink>

                <div className="date-filters">
                    <div className="filter">
                        <label>From:</label>
                        <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>
                    <div className="filter">
                        <label>To:</label>
                        <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>
                    <div className="filter">
                        <label>Select Doctor:</label>
                        <select value={selectedDoctor} onChange={(e) => setSelectedDoctor(e.target.value)}>
                            <option value="">All</option>
                            {doctorOptions1.map((doctor, index) => (
                                <option key={index} value={doctor.name}>{doctor.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <br/>
                <div className="table-responsive">
                    <table id="appointmentsTable" className="attendcalt" style={{width: "60%"}}>
                        <thead>
                            <tr>
                                <th>Doctor Name</th>
                                <th>Total No of Days</th>
                                <th>Present</th>
                                <th>Leave</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(attendanceStats).map((doctor, index) => (
                                <tr key={index}>
                                    <td>{doctor}</td>
                                    <td>{attendanceStats[doctor].totalDays}</td>
                                    <td>{attendanceStats[doctor].present}</td>
                                    <td>{attendanceStats[doctor].leave}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 12,
        position: 'relative',
    },
    header: {
        fontSize: 16,
        marginBottom: 20,
        textAlign: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f2c5fa',
        paddingVertical: 10,
    },
    headerTextContainer: {
        marginLeft: 10,
    },
    headerTitle: {
        fontSize: 25,
        color: '#853f91',
        textAlign: 'center',
    },
    headerSubtitle: {
        fontSize: 10,
        textAlign: 'center',
    },
    footer: {
        fontSize: 10,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor: '#00ACED',
        padding: 10,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        marginTop: 20,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '33.33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#853f91',
        backgroundColor: '#853f91',
        padding: 5,
    },
    tableCol: {
        width: '33.33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'white',
        padding: 5,
        backgroundColor: 'white',
        fontSize: '10px',
    },
    section: {
        marginBottom: 10,
    },
});

const PDFDocument1 = ({ attendanceStats }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <Image src={Image1} style={{ width: 50, height: 50 }} />
                <Text style={{ textAlign: "center", fontSize: "25px", color: "#853f91", marginLeft: "100px" }}>
                    THE PHYSIORA
                </Text>
                <Text style={{ textAlign: "center", fontSize: "10px", marginLeft: "80px" }}>
                    Doctor Attendance Report
                </Text>
            </View>

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableColHeader}>Doctor Name</Text>
                        <Text style={styles.tableColHeader}>Total No of Days</Text>
                        <Text style={styles.tableColHeader}>Present</Text>
                        <Text style={styles.tableColHeader}>Leave</Text>
                    </View>
                    {Object.keys(attendanceStats).map((doctor, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.tableCol}>{doctor || ""}</Text>
                            <Text style={styles.tableCol}>{attendanceStats[doctor].totalDays || '00'}</Text>
                            <Text style={styles.tableCol}>{attendanceStats[doctor].present || '00'}</Text>
                            <Text style={styles.tableCol}>{attendanceStats[doctor].leave || '00'}</Text>
                        </View>
                    ))}
                </View>
            </View>
            <Text style={styles.footer}>Footer text</Text>
        </Page>
    </Document>
);

export default AttendanceCalculate;
